<template>
  <div ref="container"
       class="relative"
  >
    <Container class="relative top-0 z-10 flex items-center py-12 md:py-20 lg:py-24 lg:h-screen lg:sticky">
      <h2 class="w-full text-4xl text-white duration-1000 md:max-w-lg lg:max-w-none md:text-5xl lg:text-9xl"
          v-html="copy"
      />
    </Container>
    <Container class="relative z-20 overflow-hidden lg:overflow-visible pb-12 md:pb-20 lg:pb-[90vh]">
      <div v-if="size === 'desktop'"
           class="grid grid-cols-3 gap-16 -mx-8"
      >
        <CardGradientVertical v-for="(card, i) in cards"
                              :title="card.title"
                              :copy="card.copy"
                              :background-gradient="card.backgroundGradient"
                              :background-image="card.backgroundImage"
                              :key="i"
                              class="row-span-4"
                              :class="[{ 'row-start-3': i === 0 }, { 'row-start-1': i === 1 }, { 'row-start-4': i === 2 }]"
        />
      </div>
      <Slider v-else>
        <CardGradientVertical v-for="(card, i) in cards"
                              :title="card.title"
                              :copy="card.copy"
                              :background-gradient="card.backgroundGradient"
                              :background-image="card.backgroundImage"
                              :key="i"
                              class="flex-shrink-0 keen-slider__slide"
        />
      </Slider>
    </Container>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useDetectSize } from '~/composables/detect-size';
const size = useDetectSize();

interface ScrollingCardsProps {
  copy: string;
  cards: {
    title: string;
    copy?: string;
    backgroundGradient?: string;
    backgroundImage?: string;
  }[];
  gradientColor?: string;
}

withDefaults(defineProps<ScrollingCardsProps>(), {
  gradientColor: 'purple-aqua'
});

const container = ref(null);
</script>
